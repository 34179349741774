import {
  AuthMfaTypeEnum,
  CompanyOnboardingStepsGetDocument,
  CompanyOnboardingStepsGetQuery,
  useAuthMfaConfigureConfirmMutation,
  useUserUpsertMutation,
} from '@generated/types.d'
import { IAdminFlowProps, LoginPayload } from '../../types'
import {
  MFA_CONFIRM,
  MFA_SETUP,
  getDashboardPage,
  getRegisterPage,
  invalidCallbackUrlList,
} from '@common/components/consts'
import { captureException, captureMessage, setUser } from '@sentry/nextjs'
import { getSession, signIn } from 'next-auth/react'
import { handleOnboardingLoginRedirect } from '@features/auth/lib/route-guards'
import Posthog from '@palqee/analytics'
import { addUserToDatalayer } from '@features/user'
import { useApolloClient } from '@apollo/client'
import { useAuthToken } from '@palqee/apollo-client'
import { useLanguageChecker } from '@common/hooks/use-language-checker'
import { useRouter } from 'next/router'
import { useSession } from '../use-session'
import { useUser } from '@features/user/hooks/use-user'
import { isRegistrationComplete } from '@features/auth/lib/guards/registration'

export const isValidCallbackUrl = (callbackUrl: string): boolean =>
  invalidCallbackUrlList.filter((re: RegExp) => re.test(callbackUrl)).length ===
  0

const captureUser = (user) => {
  // here user has pass first step of login
  // so let's capture his id
  setUser({
    id: user?.id,
    email: user?.email,
  })

  if (Posthog?.__loaded) {
    Posthog.identify(
      user?.email !== '' && user?.email !== null && !!user?.email
        ? user?.email
        : user?.id,
    )
  }
}

const loginChallengeFlow = ({ router, ...rest }) => {
  const redirectUrlPart = MFA_CONFIRM
  const { onComplete, ...onCompleteRest } = rest

  let {
    query: { callbackUrl },
  } = router

  let redirectUrl

  if (onComplete) {
    return onComplete(onCompleteRest)
  }

  if (callbackUrl) {
    callbackUrl = decodeURIComponent(callbackUrl)
    redirectUrl = `${redirectUrlPart}?callbackUrl=${encodeURIComponent(
      callbackUrl,
    )}`
  } else {
    redirectUrl = redirectUrlPart
  }

  return router.push(redirectUrl)
}

const loginMFaSetupFlow = ({ router, session }) => {
  const { user } = session

  // admin user will be routed to
  // specific mfa setup url
  if (
    user.isAdmin
    // && !!user?.passwordLastChangeAt
  ) {
    // password not set
    // thus must be employee invited
    // then made admin
    return router.push(MFA_SETUP)
  }

  // employee manages to get to this point
  const companyId = router?.query?.companyId ?? user?.defaultCompanyId

  // registration not complete and found a companyId
  // since employees are always invited
  if (!!companyId && !isRegistrationComplete(user)) {
    return router.push(getRegisterPage(companyId))
  }

  // registration is complete
  // and we have access token here  this
  // should never happen but just in case
  // take them to dashboard and send Sentry
  // message to keep track / log of this
  captureMessage(`User:${user.id} has logged in without MFA`, 'warning')
  return router.push(getDashboardPage(companyId))
}

export const useTokenFlow = () => {
  const setAuthToken = useAuthToken((s) => s.setAuthToken)
  const getTokenAndSession = async () => {
    const res = await getSession()
    setAuthToken(res?.accessToken)

    return res
  }

  return {
    handlers: {
      getTokenAndSession,
    },
  }
}

export const useAuthFlow = () => {
  const router = useRouter()
  useLanguageChecker()

  const { updateSession } = useSession()
  const {
    handlers: { getTokenAndSession },
  } = useTokenFlow()

  const runAuthHandler = async ({ session }) => {
    // update session with updated getSession request
    updateSession(session)

    // here user has pass first step of login
    // so let's identify them
    captureUser(session?.user)

    // all users require MFA so if
    // user gets {LoginSuccessPayload}
    // then they need to go through MFA setup
    switch (session?.loginPayload) {
      case LoginPayload.LoginNextChallengePayload:
        return loginChallengeFlow({ router, session })
      case LoginPayload.LoginSuccessPayload:
        return loginMFaSetupFlow({ router, session })
    }
  }

  const onLogin = async (formData) => {
    try {
      const result = await signIn('login', {
        redirect: false,
        username: formData.email,
        password: formData.password,
        rememberMe: formData.rememberMe,
      })

      // @todo see if we can change
      // status  and ok fields on error
      if (result?.ok && result?.status === 200) {
        // check if error exists

        if (result?.error) {
          throw new Error(result?.error)
        }

        try {
          // const res = await getSession()
          const res = await getTokenAndSession()
          await runAuthHandler({
            session: res,
          })
        } catch (err) {
          captureException(err)
        }
      } else if (!result?.ok) {
        throw result?.error
      }
    } catch (error) {
      throw error
    }
  }

  return {
    runAuthHandler,
    onLogin,
  }
}

const adminFlow = async ({ session, router, client }: IAdminFlowProps) => {
  let onboardingCompletedSteps = null

  const defaultCompanyId =
    session?.user?.defaultCompanyId === null
      ? undefined
      : session?.user?.defaultCompanyId

  if (defaultCompanyId) {
    const result = await client.query<CompanyOnboardingStepsGetQuery>({
      query: CompanyOnboardingStepsGetDocument,
      variables: {
        companyId: defaultCompanyId,
      },
      context: {
        headers: {
          authorization: session?.accessToken,
        },
      },
    })
    onboardingCompletedSteps = result?.data
  }

  // fetch onboarding progress for company if one exists
  return handleOnboardingLoginRedirect(
    undefined,
    onboardingCompletedSteps?.company?.onboardingStepsGet?.stepCodes || [],
    defaultCompanyId,
    router,
    session?.user,
  )
}

export const useMfaConfirm = () => {
  const client = useApolloClient()
  const router = useRouter()
  const [userUpsert] = useUserUpsertMutation()
  const { updateSession, session } = useSession()
  const { user } = useUser()
  const [authMfaConfigureConfirm] = useAuthMfaConfigureConfirmMutation({
    notifyOnNetworkStatusChange: true,
  })

  useLanguageChecker()

  const {
    handlers: { getTokenAndSession },
  } = useTokenFlow()

  const handleMfaConfirmSuccess = async (sessionArg, decodedCallbackUrl) => {
    const { isAdmin, defaultCompanyId } = sessionArg?.user

    try {
      if (!!decodedCallbackUrl && isValidCallbackUrl(decodedCallbackUrl)) {
        router.push(decodedCallbackUrl)
        return
      }

      if (!isAdmin) {
        router.push(getDashboardPage(defaultCompanyId))
        return
      }

      return adminFlow({
        session: sessionArg,
        client,
        router,
      })
    } catch (error) {
      captureException(error)
    }
  }

  const handleMfaConfigureFlow = async ({ mfaSetup, confirmationCode }) => {
    // delete users phone number if mfaType is TOTP
    // and user has phone number
    if (
      user?.phoneNumber &&
      session?.data?.user?.mfaType === AuthMfaTypeEnum.Totp
    ) {
      await userUpsert({
        variables: {
          userId: session?.data?.user?.id,
          phoneNumber: null,
        },
      })
    }

    await authMfaConfigureConfirm({
      variables: {
        mfaType: mfaSetup as AuthMfaTypeEnum,
        confirmationCode,
      },
    })

    updateSession({
      user: {
        isMfaEnabled: true,
        mfaConfirmed: true,
      },
    })

    if (!session?.data?.user?.isAdmin) {
      return router.push(
        getDashboardPage(session?.data?.user?.defaultCompanyId),
      )
    }

    return adminFlow({
      session: {
        ...session,
        user: session?.data?.user,
      },
      router,
      client,
    })
  }

  const onMfa =
    ({ mfaSetup, decodedCallbackUrl }) =>
    async (formData) => {
      const confirmationCode = formData.accessCode
        .map((code) => code.value)
        .join('')

      // here user has pass first step of login
      // so let's capture his id
      captureUser(session?.data?.user)

      if (mfaSetup && mfaSetup.length > 0) {
        addUserToDatalayer(
          client,
          String(session?.data?.user?.defaultCompanyId),
        )
        return handleMfaConfigureFlow({
          mfaSetup,
          confirmationCode,
        })
      } else {
        const { error, ok, status } = await signIn('mfa' as any, {
          redirect: false,
          confirmationCode,
          mfaChallengeKey: session?.data?.mfaChallengeKey,
          mfaType: session?.data?.user?.mfaType,
          username: session?.data?.user?.email,
        })

        if (ok && status === 200) {
          if (error) {
            throw error
          }

          try {
            const res = await getTokenAndSession()
            addUserToDatalayer(
              client,
              session?.data?.user?.defaultCompanyId ??
                String(router?.query?.companyId),
            )
            await handleMfaConfirmSuccess(res, decodedCallbackUrl)
          } catch (err) {
            captureException(err)
          }
        } else if (status === 401 && !!error && error !== 'null') {
          throw error
        } else {
          // default to invalid code, might need something better
          // in the future
          throw new Error('AuthLoginMfaCodeInvalid')
        }
      }
    }

  return {
    handleMfaConfirmSuccess,
    handleMfaConfigureFlow,
    onMfa,
  }
}
